<template>
  <div class="missing page">
    <Missing404/>
  </div>
</template>

<script>
import Missing404 from '../components/Missing404'

export default {
  name: 'Missing',
  components: {
    Missing404
  }
}

</script>

<style lang="scss" scoped>
.missing {
  background-color: $c-d;
}

</style>
