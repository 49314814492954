<template>
    <div class="missing-content">
      <div class="title-404">
        <h1 class="title h1">404</h1>
      </div>
      <div class="safety">
        <h2 class="h3">The page you are looking for can’t be found.</h2>
        <div class="back-to">
          <router-link class="link" to="/">
            <div class="svg-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="10.101" height="10.101" viewBox="0 0 10.101 10.101">
                <g id="Icon_feather-arrow-down" data-name="Icon feather-arrow-down" transform="translate(-7.146 -7.5)">
                  <path id="Path_11" data-name="Path 11" d="M18,7.5v9.394" transform="translate(-5.803 0)" fill="none" stroke="#0D0D0D" stroke-linejoin="round" stroke-width="1"/>
                  <path id="Path_12" data-name="Path 12" d="M16.894,18l-4.7,4.7L7.5,18" transform="translate(0 -5.803)" fill="none" stroke="#0D0D0D" stroke-width="1"/>
                </g>
              </svg>
            </div>
            <div id="back-text" class="meta">GO BACK TO SRI</div>
          </router-link>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Missing404'
}
</script>

<style lang="scss" scoped>

.missing-content {
  height: 100vh;
  width: 100%;
  @include grid;
  .title-404 {
    grid-column: 2 / 14;
    grid-row: 1 / 3;
    height: 100vh;
    z-index: 1;
    text-align: center;
    position: relative;
    @include tablet {
      grid-column: 2 / 10;
      height: 80vh;
    }
    @include phone {
      grid-column: 2 / 6;
    }
    .title {
      position: absolute;
      width: 100%;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
    }
  }
  .safety {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
    z-index: 2;
    margin-top: auto;
    margin-bottom: 80px;
    .back-to {
      margin-top: 12px;
      align-items: center;
      -webkit-align-items: center;
      max-width: 160px;
      cursor: pointer;
      line-height: 24px;
      height: 24px;
      #back-text {
        display: inline;
        height: 24px;
      }
      .svg-container {
        display: inline;
        margin-right: 5px;
        height: 24px;
        align-items: center;
        -webkit-align-items: center;
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}

/* .missing-content {
  height: 100vh;
  width: 100%;
  .title-404 {
    padding-top: 40vh;
    text-align: center;
    @include phone {
        padding-bottom: 20vh;
    }
  }
} */

</style>
